import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Checkbox } from 'sw-ui';
import { FlatButton } from '../../../components/button';
import { FlightCertificateButtonInAir } from '../../../components/FlightCertificateButtons/FlightCertificateButtonInAir';
import { DownloadFlightCertificateButton } from '../../../components/FlightCertificateButtons/DownloadFlightCertificateButton';

import MoneyFormat from '../../../bi/utils/money';
import { getCurrentYear, getDateObjFromStr } from '../../../utils/date';
import {
  actualIntegrationVersion,
  citiesForAir,
  getNumberVoucher,
  infoEmployee,
} from '../../../bi/utils/trip';
import {
  dateUtcFormat, formatDate, dateParseZone, momentSubPeriod,
} from '../../../bi/utils/formatDate';

import { ADDITIONAL_SERVICES, ADDITIONAL_SERVICES_LABELS } from '../../../bi/constants/additionalServices';
import { AEROEXPRESS_TARIFF_MAP } from '../../../bi/constants/aeroexpress';
import COMPONENTS from '../../../bi/constants/components';
import { SERVICETYPE } from '../../../bi/constants/serviceType';
import {
  STATUSCODES,
  TRIPBUTTONS,
  TRIPSTATUS,
  FLIGHT_TYPE_FORMAT,
  LEGAL_ENTITY_PROVIDER,
  CUSTOM_TYPES,
} from '../../../bi/constants/trips';
import {
  MONTHPATTERN,
  DEFAULTTIME,
  DAYMONTHPATTERN,
  DAYPATTERN,
  FULLPATTERN,
  DAY_OF_MONTH_TIME,
} from '../../../constants/time';
import { HOTEL_PROVIDER_VALUE } from '../../../bi/constants/hotel';

import IMG_MICE from '../../../app/styles/img/calendar_mice.svg';
import IMG_BUS from '../../../app/styles/img/bus_icon.png';

import Version from './version';
import History from './items/history';

import { InfoItem } from './taxi/components/InfoItem';
import { OperationsTaxi } from './taxi/components/OperationsTaxi';

import styles from '../styles/trip.module.scss';

const TICKETREFUNDABLE = {
  NotOffered: 'Тариф невозвратный',
  Charge: 'Возврат: со сбором',
  Included: 'Возврат: без сборов',
  NotDefined: 'Возврат: не определен',
};

const CONDITIONS = {
  NON_REFUNDABLE: 'Тариф невозвратный',
  FREE_CANCELLATION: 'Дата бесплатной отмены',
  RATE_SW: 'Тариф невозвратный(СМАРТВЭЙ)',
  BREAKFAST: 'Питание',
};

const LABELS = {
  TAXNOTFIND: 'НДС неизвестен',
  TAX: 'НДС',
  DOWNLOAD_VOUCHER: 'Скачать ваучер',
  DOWNLOAD_VOUCHER_GROUP: 'Ваучер по групповым броням',
  LEGAL_ENTITY: 'УКАЗАТЬ ЮР. ЛИЦО',
  DOWNLOAD_VOUCHER_BREAKFAST: 'Скачать ваучер без питания',
  DOWLOAD_FLIGHT_CERTIFICATE: 'Скачать справку',
  SEND_VOUCHER: 'Отправить ваучер',
  CANCEL: 'Отменить',
  CANCELLATION_CONDITION: 'Условия отмены:',
  MSK_WITH_PENALTIES: (total) => `(по московскому времени) — со штрафом ${total} ₽`,
  BEFORE_FREE: '(по московскому времени) — бесплатно',
  FROM: 'С',
  BEFORE: 'До',
  FREE_CANCELLATION_WITH_PENALTIES: (total) => `"Отмена бронирования возможна со штрафом ${total} ₽",`,
  NON_FREE_CANCELLATION: 'Бесплатная отмена бронирования невозможна',
  CHANGE: 'Изменить',
  ADD_FEE: (price) => `Сбор агентства: ${price} ₽`,
  ITEMS_IN_CART: 'Добавленные услуги в корзину',
  UNSAVE_EDIT: 'Несохраненные изменения',
  COMMISSION: 'Сбор SW:',
  BASE_PRICE: 'Цена поставщика:',
};

const TITLE = {
  USER: 'Пользователь',
  DATE: 'Дата',
  SUM: 'Сумма',
  COMISSION: 'Сбор Smartway',
  FEE: 'Добор сбора',
  CURRENCY: 'Курс',
};

const CHANGEVOUCHER = 'ОБНОВИТЬ ВАУЧЕР';
const CHANGE_CC = 'ИЗМЕНИТЬ ЦЗ';

const emplpoyeeMapping = (empl) => (
  empl.map((item) => (
    <div key={ item }>
      { `${item},` }
    </div>
  ))
);

const renderInfo = (
  number,
  numberVoucher,
  name,
  employee,
  date,
  price,
  priceDetails,
  company,
  vat,
  addFee,
  condition,
  providerName,
  officeId,
  salePoint = null,
  isEvent = false,
) => {
  const numberHtml = number || '';
  const numberVoucherHtml = numberVoucher || '';
  const officeIdHtml = officeId ? ` (${officeId})` : '';
  const salePointHtml = salePoint ? ` (${salePoint})` : '';
  const providerHtml = providerName
    ? `${providerName}${officeIdHtml || salePointHtml}`
    : '';
  const { Base = '', Commission = '' } = priceDetails;

  const employeeList = (
    <div className={ `${styles['col-1-3']} ${styles['col-info']}` }>
      { emplpoyeeMapping(employee) }
      <div className={ `${styles.col} ${styles['company-name']}` }>{ company }</div>
    </div>
  );

  const companyList = (
    <div className={ `${styles['col-1-3']} ${styles['col-info']}` }>
      { company }
    </div>
  );

  const renderList = isEvent ? companyList : employeeList;

  const conditionHtml = condition
    ? (
      <div className={ styles.condition }>
        <span className={ `sw-tooltip-wrapper ${styles.tooltip}` }>
          Условия
          <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.TOP } styles={ { display: 'flex' } }>
            { condition }
          </Tooltip>
        </span>
      </div>
    )
    : null;

  const renderCommission = () => (Commission && <div>{ `${LABELS.COMMISSION} ${Commission}` }</div>);
  const renderBasePrice = () => (Base && <div>{ `${LABELS.BASE_PRICE} ${Base}` }</div>);

  return (
    <div className={ `${styles['col-5-7']} ${styles['order-info']}` }>
      <div className={ styles.row }>
        <div className={ styles['col-1-3'] }>
          { numberHtml }
        </div>
        <div className={ styles['col-1-3'] }>
          { numberVoucherHtml }
        </div>
        <div className={ styles['col-1-3'] }>
          { name }
        </div>
      </div>
      <div className={ styles.row }>
        { renderList }
        <div className={ styles['col-1-3'] }>
          <div>
            { date }
          </div>
          <div>
            { conditionHtml }
          </div>
          <div>
            { providerHtml }
          </div>
        </div>
        <div className={ styles['col-1-3'] }>
          <div>
            { price }
          </div>
          { renderBasePrice() }
          { renderCommission() }
          <div>
            { vat }
          </div>
          <div
            className={ styles.add_fee }
          >
            { addFee }
          </div>
        </div>
      </div>
    </div>
  );
};

// TODO: вынести в сервис
const infoDate = ({
  CustomCheckInDate, CustomCheckOutDate, CheckinDate, CheckoutDate, ServiceType,
}) => {
  const isHotel = ServiceType === SERVICETYPE.HOTEL;

  const currentYear = getCurrentYear();
  const mStart = getDateObjFromStr(CheckinDate);
  const mEnd = getDateObjFromStr(CheckoutDate);

  let startDateFormat =
    mStart.year() === mEnd.year() && mStart.month() === mEnd.month()
      ? DAYPATTERN
      : MONTHPATTERN;
  let endDateFormat = MONTHPATTERN;

  if (mStart.year() !== mEnd.year() && mStart.year() === currentYear) {
    endDateFormat = FULLPATTERN;
  }
  if (mStart.year() !== mEnd.year() && mStart.year() !== currentYear) {
    startDateFormat = FULLPATTERN;
    endDateFormat = FULLPATTERN;
  }

  if (isHotel) {
    if (CustomCheckInDate) {
      startDateFormat = startDateFormat === DAYPATTERN
        ? `${MONTHPATTERN} ${DEFAULTTIME}`
        : `${startDateFormat} ${DEFAULTTIME}`;
    }
    if (CustomCheckOutDate) {
      endDateFormat += ` ${DEFAULTTIME}`;
    }
  }

  const rStart = mStart.format(startDateFormat);
  const rEnd = mEnd.format(endDateFormat);

  if (mStart.clone().startOf('day')
    .isSame(mEnd.clone().startOf('day')) && !isHotel) {
    return rEnd;
  }

  return `${rStart} - ${rEnd}`;
};

const infoPrice = ({ Price }, opts) => (Price || MoneyFormat.symbolWithMoneyWithDecimal(opts.item.ActualVersion.Price));
const infoPriceDetails = ({ PriceDetails: { Base = '', Commission = '' } }) => (
  {
    Base: MoneyFormat.symbolWithMoneyWithDecimal(Base),
    Commission: MoneyFormat.symbolWithMoneyWithDecimal(Math.abs(Commission)),
  }
);

const infoVat = (opts) => {
  const { item: { ActualVersion: { Vat } } } = opts;

  if (isNaN(Vat)) {
    return LABELS.TAXNOTFIND;
  }

  return (
    `${LABELS.TAX} ( ${MoneyFormat.symbolWithMoneyWithDecimal(Vat)} )`
  );
};

const addFee = (opts) => !!opts.item.AgentFee && LABELS.ADD_FEE(opts.item.AgentFee);

const infoCompany = (companies, { CompanyId: orderCompanyId }) => {
  const foundCompany = companies.find(({ id }) => id === orderCompanyId);

  return foundCompany ? foundCompany.name : '';
};

const renderAir = (data, trips, opts, companies, order) => {
  const history = <History items={ trips } opts={ opts } />;

  const name = citiesForAir(data.Routes);
  const number = data.TicketsExtended.map((num) => `${num.PNR}`).join(', ');
  const numberVoucher = getNumberVoucher(data.TicketsExtended);
  const isTicketRefundable = data.Fare && data.Fare.IsTicketRefundable ? data.Fare.IsTicketRefundable : 'NotDefined';
  const providerName = data.ProviderName || null;
  const officeId = data.ProviderInfo && data.ProviderInfo.OfficeId;
  const salePoint = data.ProviderInfo && data.ProviderInfo.SalePoint;

  let baggageIncluded = null;

  if (data.Fare && data.Fare.IsBaggageIncluded === 'Included') {
    baggageIncluded = `Багаж включен${data.Fare.BaggagePlaces ? `: ${data.Fare.BaggagePlaces}` : ''}`;
  } else if (data.Fare && data.Fare.IsBaggageIncluded !== 'NotDefined') {
    baggageIncluded = 'Багаж платный';
  } else if (!data.Fare || data.Fare.IsBaggageIncluded === 'NotDefined') {
    baggageIncluded = 'Багаж: не определен';
  }

  const condition = `${TICKETREFUNDABLE[isTicketRefundable]}; ${baggageIncluded}`;
  const { AdditionalServices = [] } = data;
  const newAdditionalServices = AdditionalServices.map((additionalService) => {
    const newAdditionalService = additionalService;
    if (newAdditionalService.Service.Comission === undefined) {
      newAdditionalService.Service.Comission = 0;
    }

    return newAdditionalService;
  });
  const additionalServicesPrice = newAdditionalServices
    .reduce((acc, { Service: { Price, Comission } }) => acc + Price + Comission, 0);

  const isCancelled = order.Status === STATUSCODES.CANCEL;
  const price = isCancelled
    ? infoPrice(data, opts)
    : MoneyFormat.symbolWithMoneyWithDecimal(opts.item.ActualVersion.Price - additionalServicesPrice);

  const info = renderInfo(
    number,
    numberVoucher,
    name,
    infoEmployee(data),
    infoDate(data),
    price,
    false,
    infoCompany(companies, order),
    infoVat(opts),
    addFee(opts),
    condition,
    providerName,
    officeId,
    salePoint,
  );

  return {
    description: 'flight',
    history,
    info,
  };
};

const renderTrain = (data, trips, opts, companies, order) => {
  const history = <History items={ trips } opts={ opts } />;
  const number = data.OrderId;
  const numberVoucher = data.TicketNumber;
  const providerName = order.ProviderName || null;

  const info = renderInfo(
    number,
    numberVoucher,
    `${data.StationDepart} - ${data.StationArrive}`,
    infoEmployee(data),
    infoDate(data),
    infoPrice(data, opts),
    false,
    infoCompany(companies, order),
    infoVat(opts),
    addFee(opts),
    null,
    providerName,
  );

  return {
    description: 'directions_railway',
    history,
    info,
  };
};

const renderAeroexpress = (data, trips, opts, companies, order) => {
  const history = <History items={ trips } opts={ opts } />;
  const { Trip, Ticket } = data;

  const tariff = AEROEXPRESS_TARIFF_MAP[Trip.TariffType];
  const numberVoucher = Ticket.Id;

  const info = renderInfo(
    tariff,
    numberVoucher,
    null,
    infoEmployee(data),
    infoDate(order),
    infoPrice(data, opts),
    false,
    infoCompany(companies, order),
    infoVat(opts),
    addFee(opts),
  );

  return {
    description: 'smartway-aeroexpress-temp-big',
    history,
    info,
  };
};

const renderBreakfast = (breakfast) => (
  <div>
    { CONDITIONS.BREAKFAST }
    :
    { ' ' }
    { breakfast }
  </div>
);

const renderHotelCancellation = ({
  cancellationPenalties, cancellationDate, cancellationInfo, breakfast,
}) => {
  const headerConditions = (cancellationPenalties && cancellationPenalties.length > 1)
    ? (<div>{ LABELS.CANCELLATION_CONDITION }</div>)
    : null;

  const createItemList = (prefix, from, total, index) => (
    <div key={ `${from}_${index}` } className={ styles.hotel_cancellation_item }>
      { prefix }
      <strong className={ styles.red_text }>
        &nbsp;
        { dateUtcFormat(from, DAY_OF_MONTH_TIME) }
        &nbsp;
      </strong>
      { LABELS.MSK_WITH_PENALTIES(MoneyFormat.money(total)) }
    </div>
  );

  let isFreeCancellation = '';
  const penaltyList = [];
  const freeCancellation = (
    <div>
      { LABELS.BEFORE }
      <strong className={ styles.red_text }>
      &nbsp;
        { cancellationDate }
      &nbsp;
      </strong>
      { LABELS.BEFORE_FREE }
    </div>
  );

  const cancellationNotFromTrip = () => {
    if (!cancellationPenalties) {
      return freeCancellation;
    }

    if (cancellationInfo) {
      cancellationPenalties.forEach(({
        From, Total, Additional,
      }, i) => penaltyList.push(!Additional ? createItemList(LABELS.FROM, From, Total, i) : null));

      return freeCancellation;
    }

    isFreeCancellation = cancellationPenalties.length === 1
      ? LABELS.FREE_CANCELLATION_WITH_PENALTIES(cancellationPenalties[0].Total)
      : '';

    if (cancellationPenalties.length > 1) {
      cancellationPenalties.forEach(({
        From, Total, Additional,
      }, i) => {
        if (!Additional) {
          if (i === 0) {
            penaltyList.push(createItemList(LABELS.BEFORE, momentSubPeriod(cancellationPenalties[1].From, 1, 'minutes'), Total, i));
          } else {
            penaltyList.push(createItemList(LABELS.FROM, From, Total, i));
          }
        } else {
          if (!penaltyList.length) {
            isFreeCancellation = LABELS.NON_FREE_CANCELLATION;
          }
          penaltyList.push(null);
        }
      });
    }

    return isFreeCancellation;
  };

  isFreeCancellation = cancellationNotFromTrip();

  return (
    <div className={ styles.hotel_cancellation_wrap }>
      { renderBreakfast(breakfast) }
      { headerConditions }
      { isFreeCancellation }
      { penaltyList }
    </div>
  );
};

renderHotelCancellation.propTypes = {
  cancellationPenalties: PropTypes.array.isRequired,
  cancellationDate: PropTypes.string.isRequired,
  cancellationInfo: PropTypes.string.isRequired,
  breakfast: PropTypes.bool.isRequired,
};

const renderConditionHotel = ({
  isCustom, breakfast, cancellationPenalties, cancellationDate, cancellationInfo,
}) => {
  if (isCustom) {
    return (
      <div>
        { renderBreakfast(breakfast) }
        <div>{ CONDITIONS.RATE_SW }</div>
      </div>
    );
  }

  if (!cancellationInfo) {
    return (
      <div>
        { renderBreakfast(breakfast) }
        <div>{ CONDITIONS.NON_REFUNDABLE }</div>
      </div>
    );
  }

  return renderHotelCancellation({
    cancellationPenalties,
    cancellationDate,
    cancellationInfo,
    breakfast,
  });
};

renderConditionHotel.propTypes = {
  isCustom: PropTypes.bool.isRequired,
  breakfast: PropTypes.bool.isRequired,
  cancellationPenalties: PropTypes.array.isRequired,
  cancellationDate: PropTypes.string.isRequired,
  cancellationInfo: PropTypes.string.isRequired,
};

const renderHotel = (data, trips, opts, companies, order) => {
  const history = <History items={ trips } opts={ opts } />;
  const number = data.ReservationNumber;
  const numberVoucher = data.ProviderName;
  const cancellationDate = data.Room.FreeCancellation
    ? dateUtcFormat(data.Room.FreeCancellation, DAY_OF_MONTH_TIME)
    : null;
  const condition = renderConditionHotel({
    isCustom: data.IsCustom,
    breakfast: data.Room.Meal.Name,
    cancellationPenalties: data.Room.CancellationPenalties,
    cancellationDate,
    cancellationInfo: data.Room.FreeCancellation,
  });

  const info = renderInfo(
    number,
    numberVoucher,
    data.Name,
    infoEmployee(data),
    infoDate(data),
    infoPrice(data, opts),
    infoPriceDetails(data),
    infoCompany(companies, order),
    infoVat(opts),
    addFee(opts),
    condition,
  );

  return {
    description: 'hotel',
    history,
    info,
  };
};

const renderTransfer = (data, trips, opts, companies, order) => {
  const history = <History items={ trips } opts={ opts } />;

  const number = data.ProviderOrderId;

  const info = renderInfo(
    number,
    null,
    (`${data.StartPlace.Address} - ${data.EndPlace.Address}`),
    infoEmployee(data),
    infoDate(data),
    infoPrice(data, opts),
    infoPriceDetails(data),
    infoCompany(companies, order),
    infoVat(opts),
    addFee(opts),
  );

  return {
    description: 'airport_shuttle',
    history,
    info,
  };
};

const renderTaxi = ({
  data, trips, opts, companies, order,
}) => {
  const {
    onChangeCheckboxOperations,
    checkboxesOperations,
    onOpenDeleteOperationsDialog,
    onOpenMoveOperationsDialog,
    statusBook,
    onCheckAllOperations,
    checkAllOperational,
    flagReplaceTaxi,
    list,
    operationsInf,
  } = opts;

  const isAwaitingConfirmation = statusBook === TRIPSTATUS.AWAITINGCONFIRMATION;

  const history = <History items={ trips } opts={ opts } />;

  const {
    VoucherId, Voucher, ReserveData,
  } = data;

  const voucherOperations = Voucher && Voucher.Operations ? Voucher.Operations : [];

  const info = (<InfoItem
    voucherId={ VoucherId }
    voucher={ Voucher }
    reserveData={ ReserveData }
    company={ infoCompany(companies, order) }
  />);

  const operations = (
    <OperationsTaxi
      voucherId={ VoucherId }
      operations={ voucherOperations }
      onChangeCheckbox={ onChangeCheckboxOperations }
      onCheckAllOperations={ onCheckAllOperations }
      checkboxesOperations={ checkboxesOperations }
      onOpenDeleteOperationsDialog={ onOpenDeleteOperationsDialog }
      onOpenMoveOperationsDialog={ onOpenMoveOperationsDialog }
      isStartEdit={ isAwaitingConfirmation }
      checkAllOperational={ checkAllOperational }
      flagReplaceTaxi={ flagReplaceTaxi }
      countOrders={ list.length }
      operationsInf={ operationsInf }
      order={ order }
    />
  );

  return {
    description: 'local_taxi',
    history,
    info,
    operations,
  };
};

const renderVipHall = (data, trips, opts, companies, order) => {
  const {
    ProviderOrderId,
    Flight,
  } = data;

  const history = <History items={ trips } opts={ opts } />;

  const number = ProviderOrderId;

  const transitPlace = Flight.find(({ IsTransit }) => IsTransit);

  const place = (Flight.length > 1
    ? `${transitPlace.DepartureAirport.City}, ${transitPlace.DepartureAirport.Name}`
    : Flight.map(({
      DepartureAirport, ArrivalAirport, Type,
    }) => {
      if (Type === FLIGHT_TYPE_FORMAT.DEPARTURE) {
        return `${DepartureAirport.City}, ${DepartureAirport.Name}`;
      }

      return `${ArrivalAirport.City}, ${ArrivalAirport.Name}`;
    }));

  const info = renderInfo(
    number,
    null,
    place,
    infoEmployee(data),
    infoDate(data),
    infoPrice(data, opts),
    false,
    infoCompany(companies, order),
    infoVat(opts),
    addFee(opts),
  );

  return {
    history,
    info,
  };
};

const renderAirAdditinalService = (data, trips, opts, companies, order) => {
  const history = <History items={ trips } opts={ opts } />;

  const segmentsInfo = data.Segments.reduce(
    (acc, segment) => `${acc} ${segment.DepartureAirport.City} - ${segment.ArrivalAirport.City},`,
    '',
  ).slice(0, -1);

  const info = renderInfo(
    data.OrderId,
    data.EmdNumber,
    `${data.Name}, ${segmentsInfo}`,
    infoEmployee(data),
    infoDate(data),
    infoPrice(data, opts),
    false,
    infoCompany(companies, order),
    infoVat(opts),
    addFee(opts),
  );

  return {
    description: 'border_all',
    history,
    info,
  };
};

const renderCustom = (data, trips, opts, companies, order) => {
  const history = <History items={ trips } opts={ opts } />;
  const flagChangeProject = opts.flagChangeProject;

  const info = renderInfo(
    data.OrderId,
    null,
    data.Name,
    infoEmployee(data),
    infoDate(data),
    infoPrice(data, opts),
    infoPriceDetails(data),
    infoCompany(companies, order),
    infoVat(opts),
    addFee(opts),
    flagChangeProject,
  );

  return {
    description: 'border_all',
    history,
    info,
  };
};

const renderEvent = (data, trips, opts, companies, order) => {
  const history = <History items={ trips } opts={ opts } />;

  const info = renderInfo(
    data.OrderId,
    null,
    data.Name,
    infoEmployee(data),
    infoDate(data),
    infoPrice(data, opts),
    false,
    infoCompany(companies, order),
    infoVat(opts),
    addFee(opts),
    false,
    null,
    null,
    true,
  );

  return {
    description: 'border_all',
    history,
    info,
  };
};

const renderInsurance = (data, trips, opts, companies, order) => {
  const history = <History items={ trips } opts={ opts } />;

  const info = renderInfo(
    data.OrderId,
    null,
    data.Name,
    infoEmployee(data),
    infoDate(data),
    infoPrice(data, opts),
    infoPriceDetails(data),
    infoCompany(companies, order),
    infoVat(opts),
    addFee(opts),
  );

  return {
    description: 'border_all',
    history,
    info,
  };
};

const renderBus = (data, trips, opts, companies, order) => {
  const history = <History items={ trips } opts={ opts } />;

  const info = renderInfo(
    data.ChatId,
    data.TicketNumber,
    data.Name,
    infoEmployee(data),
    infoDate(data),
    infoPrice(data, opts),
    infoPriceDetails(data),
    infoCompany(companies, order),
    infoVat(opts),
    addFee(opts),
  );

  return {
    description: 'border_all',
    history,
    info,
  };
};

const renderFlightCertificate = (data, trips, opts) => {
  const history = <History items={ trips } opts={ opts } />;
  const title = `Справка-подтверждение перелета к билету №${data.FlightData.Tickets}`;
  const price = infoPrice(data, opts);

  const vat = infoVat(opts);
  const fee = addFee(opts);

  const info = (
    <div className={ styles.flight_certificate_wrap }>
      <div>{ title }</div>
      <div className={ styles.fc_price }>
        <div>{ price }</div>
        <div>{ vat }</div>
        <div>
          { ' ' }
          { fee }
          { ' ' }
        </div>
      </div>
    </div>
  );

  return {
    description: 'assignment',
    history,
    info,
  };
};

const renderApartments = (data, trips, opts) => {
  const history = <History items={ trips } opts={ opts } />;

  return {
    description: `Апартаменты ${data.Appartment.Name}`,
    price: data.Price,
    history,
  };
};

const renderActionOnCancelTicket = ({ opts, order: orderWithoutAnalytics }) => {
  const {
    item: { UserAnalytics = [] },
    statusBook,
    handleShowEditCC,
    flagChangeProject,
  } = opts;

  const order = {
    ...orderWithoutAnalytics,
    UserAnalytics,
  };

  const isAwaitingConfirmation = statusBook === TRIPSTATUS.AWAITINGCONFIRMATION;

  const changeССHtml = () => (flagChangeProject
    ? (
      <FlatButton
        label={ CHANGE_CC }
        onClick={ () => handleShowEditCC(true, order) }
      />
    )
    : null);

  if (isAwaitingConfirmation) {
    return (
      <div className={ `${styles['col-2-16']} ${styles.actions}` }>
        { changeССHtml() }
      </div>
    );
  }

  return null;
};

const renderManualCancellationButton = ({ opts, order }) => {
  const {
    item: { ActualVersion: { Status } },
    onManualCancelItem,
    manualTrainCancel,
  } = opts;

  if (!manualTrainCancel || Status === STATUSCODES.CANCEL) {
    return null;
  }

  return <FlatButton label={ TRIPBUTTONS.MANUAL_CANCELLATION } onClick={ () => onManualCancelItem(order) } />;
};

const renderDefaultAction = ({ opts, order: orderWithoutAnalytics }) => {
  const {
    item: {
      ActualVersion: {
        ServiceType,
        Status,
        JsonData,
        CompanyId,
        TripItemId,
        Id,
      },
      SupplierDocument,
      UserAnalytics = [],
    },
    statusBook,
    handlerEditItem,
    onHandlerAddServiceItem,
    onHandlerVoidingModal,
    handlerCancelItem,
    onCancelAirPenaltyItem,
    handlerAutoCancelItem,
    handlerChangeTicketStatus,
    handlerDeleteItem,
    handleShowViewItem,
    handleShowEditCC,
    flagChangeProject,
    onDownloadVoucher,
    onDownloadVoucherGroupLiving,
    onDownloadBreakfastVoucher,
    onDownloadFlightCertificate,
    onGetStatusFlightCertificate,
    onBookFlightCertificate,
    onChangeTrainVoucher,
    onChangeAeroExpressVoucher,
    onAddTaxi,
    handlerSendVoucher,
    waitResChangeVoucher,
    prohibitionVouchers,
    companySettings: {
      freeFlightCertificate,
      removeFlightCertificate,
    },
    showButtonFlightCertificateInAir,
    handleLegalEntityButton,
    legalAllowEditingValues,
    flagAandaContractorChange,
    isTripPaidByPersonalFunds,
  } = opts;

  const data = JSON.parse(JsonData);

  const isAwaitingConfirmation = statusBook === TRIPSTATUS.AWAITINGCONFIRMATION;
  const isAwaitingTicketing = statusBook === TRIPSTATUS.AWAITINGTICKETING;
  const isBooked = statusBook === 'Booked';
  const isCompleted = statusBook === 'Completed';
  const isFlightCertificate = ServiceType === SERVICETYPE.FLIGHT_CERTIFICATE;
  const isProhibitionVoucher = prohibitionVouchers.some((id) => id === CompanyId);

  const { OrderId, AirTripItemId } = data;

  const order = {
    ...orderWithoutAnalytics,
    UserAnalytics,
    Id,
  };

  const newOrder = ServiceType === SERVICETYPE.HOTEL
    ? {
      ...order,
      SupplierDocument,
    }
    : order;

  const legalEntityButton = () => {
    const allowEditing = legalAllowEditingValues
      .find(({ AllowEditing, TripItemId: id }) => id === TripItemId && AllowEditing);
    const isVisibleLegalButton = order.ProviderName === LEGAL_ENTITY_PROVIDER
      && !!allowEditing
      && flagAandaContractorChange;

    if (!isVisibleLegalButton) return null;

    return (
      <FlatButton
        label={ LABELS.LEGAL_ENTITY }
        onClick={ () => handleLegalEntityButton(order.TripItemId) }
      />
    );
  };

  const downloadButton = isFlightCertificate
    ? (
      <DownloadFlightCertificateButton
        airItemId={ AirTripItemId }
        onDownload={ onDownloadFlightCertificate }
        onGetStatus={ onGetStatusFlightCertificate }
      />
    )
    : (
      <FlatButton
        label={ LABELS.DOWNLOAD_VOUCHER }
        onClick={ () => onDownloadVoucher(order) }
      />
    );

  const downloadHtml = ServiceType !== SERVICETYPE.TAXI ? downloadButton : null;
  const buttonVoucherGroupLiving = data.Type === CUSTOM_TYPES.GROUP_ACCOMODATION && data.Hotel
    ? (
      <FlatButton
        label={ LABELS.DOWNLOAD_VOUCHER_GROUP }
        onClick={ () => onDownloadVoucherGroupLiving(order.TripItemId) }
      />
    )
    : null;

  const renderDownloadFlightCertificate = () => {
    if (!showButtonFlightCertificateInAir || isTripPaidByPersonalFunds) return null;

    return (
      <FlightCertificateButtonInAir
        airItemId={ TripItemId }
        isFreeFlightCertificate={ freeFlightCertificate }
        isRemoveFlightCertificate={ removeFlightCertificate }
        onDownload={ onDownloadFlightCertificate }
        onGetStatus={ onGetStatusFlightCertificate }
        onBook={ onBookFlightCertificate }
      />
    );
  };

  const downloadHtmlBreakfast = (
    <FlatButton label={ LABELS.DOWNLOAD_VOUCHER_BREAKFAST } onClick={ () => onDownloadBreakfastVoucher(order) } />
  );

  const sendHtml = !isFlightCertificate && ServiceType !== SERVICETYPE.TAXI && (
    <FlatButton
      disabled={ isProhibitionVoucher }
      label={ LABELS.SEND_VOUCHER }
      onClick={ () => handlerSendVoucher(order) }
    />
  );

  const getButtonHtml = (label, onClick) => (
    <FlatButton label={ label } onClick={ () => onClick(order) } />
  );

  const changeTrainHtml = (
    <FlatButton
      label={ CHANGEVOUCHER }
      loading={ waitResChangeVoucher }
      onClick={ () => onChangeTrainVoucher(order) }
    />
  );

  const changeССHtml = () => (flagChangeProject && (
    ServiceType === SERVICETYPE.AEROEXPRESS
    || ServiceType === SERVICETYPE.FLIGHT_CERTIFICATE
    || ServiceType === SERVICETYPE.TRAIN
    || ServiceType === SERVICETYPE.CUSTOM
    || ServiceType === SERVICETYPE.TAXI
    || ServiceType === SERVICETYPE.AIR_ADDITIONAL_SERVICE)
    ? (
      <FlatButton
        label={ CHANGE_CC }
        onClick={ () => handleShowEditCC(true, order) }
      />
    )
    : null
  );

  let editButton = null;

  if (isAwaitingTicketing) {
    if (ServiceType !== SERVICETYPE.CUSTOM && Status === STATUSCODES.METADATA) {
      const editLabel = 'Ручной ввод';
      const deleteLabel = 'Удалить';
      editButton = (
        <div className={ styles['action-button'] }>
          <FlatButton label={ editLabel } onClick={ () => handlerEditItem(order) } />
          <FlatButton label={ deleteLabel } onClick={ () => handlerDeleteItem(order) } />
        </div>
      );
    }
  } else if (Status !== STATUSCODES.HIDDEN
    && ServiceType !== SERVICETYPE.AEROEXPRESS
    && ServiceType !== SERVICETYPE.TRAIN
    && ServiceType !== SERVICETYPE.TAXI) {
    editButton = (
      <FlatButton label={ LABELS.CHANGE } onClick={ () => handlerEditItem(newOrder) } />
    );
  }

  let cancelItem;
  if (isAwaitingConfirmation
    && Status !== STATUSCODES.HIDDEN
    && ServiceType !== SERVICETYPE.AEROEXPRESS
    && ServiceType !== SERVICETYPE.TAXI
    && ServiceType !== SERVICETYPE.INSURANCE) {
    cancelItem = (
      <FlatButton label={ LABELS.CANCEL } onClick={ () => handlerCancelItem(order) } />
    );
  }

  const addTaxiTrip = ServiceType === SERVICETYPE.TAXI
    ? (
      <FlatButton
        label={ TRIPBUTTONS.ADD_TAXI_TRIP }
        onClick={ () => onAddTaxi(order) }
      />
    )
    : null;

  let autoCancelItem;
  let buttonChangeССHtml;

  if (ServiceType === SERVICETYPE.AIR) {
    autoCancelItem = (
      <FlatButton
        label={ TRIPBUTTONS.CANCELLATION }
        onClick={ () => handlerAutoCancelItem(order) }
      />
    );
  }

  if (ServiceType === SERVICETYPE.AEROEXPRESS) {
    autoCancelItem = (
      <FlatButton
        label={ TRIPBUTTONS.CANCELTRIP }
        onClick={ () => handlerAutoCancelItem(order) }
      />
    );

    buttonChangeССHtml = changeССHtml();
  }

  let changeTicketStatus;
  if (ServiceType === SERVICETYPE.AIR) {
    changeTicketStatus = (
      <FlatButton
        label={ TRIPBUTTONS.CHANGETICKETSTATUS }
        onClick={ () => handlerChangeTicketStatus(order) }
      />
    );
  }

  if (ServiceType === SERVICETYPE.TRAIN) {
    cancelItem = OrderId && getButtonHtml(LABELS.CANCEL, handlerCancelItem);
  }

  if (ServiceType === SERVICETYPE.AIR) {
    cancelItem = getButtonHtml(LABELS.CANCEL, onCancelAirPenaltyItem);
  }

  if ((isBooked || isCompleted) && ServiceType === SERVICETYPE.HOTEL) {
    return (
      <div className={ `${styles['col-2-16']} ${styles.actions}` }>
        { downloadHtml }
        { sendHtml }
        { downloadHtmlBreakfast }
        { legalEntityButton() }
      </div>
    );
  }

  if ((isBooked || isCompleted) && ServiceType === SERVICETYPE.CUSTOM) {
    const isVoucher = JSON.parse(JsonData).VoucherFileName;
    const voucherDownload = isVoucher ? downloadHtml : null;
    const voucherSend = isVoucher ? sendHtml : null;

    return (
      <div className={ `${styles['col-2-16']} ${styles.actions}` }>
        { voucherDownload }
        { buttonVoucherGroupLiving }
        { voucherSend }
      </div>
    );
  }

  if ((isBooked || isCompleted) && ServiceType === SERVICETYPE.TRAIN) {
    return (
      <div className={ `${styles['col-2-16']} ${styles.actions}` }>
        { downloadHtml }
        { sendHtml }
      </div>
    );
  } else if ((isAwaitingConfirmation || isAwaitingTicketing) && status !== STATUSCODES.CANCEL) {
    if (ServiceType === SERVICETYPE.TRAIN) {
      const viewButtonTrainHtml = <FlatButton label={ TRIPBUTTONS.VIEW } onClick={ handleShowViewItem(order) } />;

      return (
        <div className={ `${styles['col-2-16']} ${styles.actions}` }>
          { editButton }
          { cancelItem }
          { renderManualCancellationButton({ opts, order }) }
          { viewButtonTrainHtml }
          { downloadHtml }
          { sendHtml }
          { changeTrainHtml }
          { changeССHtml() }
        </div>
      );
    }

    if (ServiceType === SERVICETYPE.AIR) {
      const renderAddServiceButton = () => {
        if (isTripPaidByPersonalFunds) return null;

        return (
          <FlatButton
            label={ TRIPBUTTONS.ADD_SERVICE }
            onClick={ () => onHandlerAddServiceItem(({ ...order, ServiceType: SERVICETYPE.AIR_ADDITIONAL_SERVICE })) }
          />
        );
      };

      const voidingButton = !isAwaitingTicketing
        && <FlatButton label={ TRIPBUTTONS.VOIDING } onClick={ () => onHandlerVoidingModal(order) } />;

      return (
        <div className={ `${styles['col-2-16']} ${styles.actions}` }>
          { editButton }
          { renderAddServiceButton() }
          { cancelItem }
          { autoCancelItem }
          { changeTicketStatus }
          { voidingButton }
          { downloadHtml }
          { sendHtml }
        </div>
      );
    }

    if (ServiceType === SERVICETYPE.CUSTOM) {
      const isVoucher = !!JSON.parse(JsonData).VoucherFileName;
      const voucherButtons = isVoucher && [downloadHtml, sendHtml];

      return (
        <div className={ `${styles['col-2-16']} ${styles.actions}` }>
          { editButton }
          { cancelItem }
          { voucherButtons }
          { changeССHtml() }
        </div>
      );
    }

    if (ServiceType === SERVICETYPE.AEROEXPRESS) {
      return (
        <div className={ `${styles['col-2-16']} ${styles.actions}` }>
          { downloadHtml }
          { sendHtml }
          <FlatButton
            label={ CHANGEVOUCHER }
            loading={ waitResChangeVoucher }
            onClick={ () => onChangeAeroExpressVoucher(order) }
          />
          { renderManualCancellationButton({ opts, order }) }
          { autoCancelItem }
          { changeССHtml() }
        </div>
      );
    }

    if (ServiceType === SERVICETYPE.AIR_ADDITIONAL_SERVICE) {
      return (
        <div className={ `${styles['col-2-16']} ${styles.actions}` }>
          { cancelItem }
          { downloadHtml }
          { sendHtml }
          { changeССHtml() }
        </div>
      );
    }

    return (
      <div className={ `${styles['col-2-16']} ${styles.actions}` }>
        { editButton }
        { cancelItem }
        { downloadHtml }
        { changeССHtml() }
        { sendHtml }
        { autoCancelItem }
        { buttonChangeССHtml }
        { addTaxiTrip }
        { legalEntityButton() }
      </div>
    );
  }

  return (
    <div className={ `${styles['col-2-16']} ${styles.actions}` }>
      { downloadHtml }
      { sendHtml }
      { renderDownloadFlightCertificate() }
    </div>
  );
};

renderDefaultAction.propTypes = {
  opts: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};

const render = (opts) => {
  let description;
  let price;
  let history;
  let info;
  let vat;
  let integration;
  let operations = null;
  const trips = opts.item.Versions;
  const order = opts.item.ActualVersion;
  const companies = opts.companies;
  const data = JSON.parse(order.JsonData);

  let result;

  switch (order.ServiceType) {
    case SERVICETYPE.AIR: {
      result = renderAir(data, trips, opts, companies, order);
      break;
    }
    case SERVICETYPE.HOTEL: {
      result = renderHotel(data, trips, opts, companies, order);
      break;
    }
    case SERVICETYPE.APARTMENTS: {
      result = renderApartments(data, trips, opts);
      break;
    }
    case SERVICETYPE.TRANSFER: {
      result = renderTransfer(data, trips, opts, companies, order);
      break;
    }
    case SERVICETYPE.TRAIN: {
      result = renderTrain(data, trips, opts, companies, order);
      break;
    }
    case SERVICETYPE.AEROEXPRESS: {
      result = renderAeroexpress(data, trips, opts, companies, order);
      break;
    }
    case SERVICETYPE.CUSTOM: {
      result = renderCustom(data, trips, opts, companies, order);
      break;
    }
    case SERVICETYPE.FLIGHT_CERTIFICATE: {
      result = renderFlightCertificate(data, trips, opts);
      break;
    }
    case SERVICETYPE.TAXI: {
      result = renderTaxi({
        data, trips, opts, companies, order,
      });
      break;
    }
    case SERVICETYPE.VIP_HALL: {
      result = renderVipHall(data, trips, opts, companies, order);
      break;
    }
    case SERVICETYPE.AIR_ADDITIONAL_SERVICE: {
      result = renderAirAdditinalService(data, trips, opts, companies, order);
      break;
    }
    case SERVICETYPE.EVENT: {
      result = renderEvent(data, trips, opts, companies, order);
      break;
    }
    case SERVICETYPE.INSURANCE: {
      result = renderInsurance(data, trips, opts, companies, order);
      break;
    }
    case SERVICETYPE.BUS: {
      result = renderBus(data, trips, opts, companies, order);
      break;
    }
  }

  const showActions = opts.item.ActualVersion.Status === STATUSCODES.CANCEL;

  const actions = showActions ? renderActionOnCancelTicket({ opts, order }) : renderDefaultAction({ opts, order });

  if (result) {
    description = result.description;
    price = MoneyFormat.symbolWithMoneyWithDecimal(opts.item.ActualVersion.Price);
    history = result.history;
    info = result.info;
    vat = isNaN(opts.item.ActualVersion.Vat) ? 'Нет Данных' : MoneyFormat.symbolWithMoneyWithDecimal(opts.item.ActualVersion.Vat);
    integration = opts.integration;
    operations = result.operations ? result.operations : null;
  }

  order.OrderTripItemId = opts.item.Id;

  return {
    description,
    price,
    history,
    order,
    actions,
    info,
    vat,
    integration,
    operations,
  };
};

const renderIcon = (description, serviceType) => {
  if (serviceType === SERVICETYPE.AEROEXPRESS) {
    return (
      <div className={ `smartway-aeroexpress-temp-big ${styles['aeroexpress-icon']}` } />
    );
  }

  if (serviceType === SERVICETYPE.VIP_HALL) {
    return (
      <div className={ `smartway-vip-hall ${styles['icon-services']}` } />
    );
  }

  if (serviceType === SERVICETYPE.AIR_ADDITIONAL_SERVICE) {
    return (
      <div className={ `airplane_ticket ${styles['icon-services']}` } />
    );
  }

  if (serviceType === SERVICETYPE.EVENT) {
    return (
      <img className={ styles.mice } src={ IMG_MICE } alt='MICE' />
    );
  }

  if (serviceType === SERVICETYPE.BUS) {
    return (
      <img className={ styles.bus } src={ IMG_BUS } alt='BUS' />
    );
  }

  return (
    <i className={ `material-icons ${styles['icon-services']}` }>{ description }</i>
  );
};

const renderAdditionalServices = (checkboxItem = null, opts) => {
  const {
    item: { ActualVersion: actualVersion, Id },
    companies,
  } = opts;

  if (actualVersion.ServiceType !== SERVICETYPE.AIR) {
    return null;
  }

  let itemData = null;

  try {
    itemData = JSON.parse(actualVersion.JsonData);
  } catch (error) { /* :^) */ }

  if (!itemData) {
    return null;
  }

  const {
    AdditionalServices = [], Tickets = [null], Routes = [],
  } = itemData;

  const segments = Routes.reduce((acc, { Segments = [] }) => [
    ...acc,
    ...Segments,
  ], []);

  const isCancelled = actualVersion.Status === STATUSCODES.CANCEL;

  return AdditionalServices
    .map(({
      Service, SegmentId, EmdNumber, TravellerId, Type,
    }, index) => {
      const segment = segments.find(({ ID: segmentId }) => segmentId === SegmentId);
      const date = getDateObjFromStr(segment.DepartureDate).format(MONTHPATTERN);
      const numberVoucherHtml = ADDITIONAL_SERVICES[Type].inputs.map((field, ind) => (
        <p key={ ind }>{ `${ADDITIONAL_SERVICES_LABELS[field]}: ${Service[field]}` }</p>
      ));

      let name = '';

      ADDITIONAL_SERVICES.forEach((service) => {
        if (service.type === Type) name = `${service.name}, билет #${Tickets[0]}`;
      });

      const priceComission = Service.Comission || 0;

      const price = isCancelled
        ? 0
        : MoneyFormat.symbolWithMoneyWithDecimal(Service.Price + priceComission);

      const info = renderInfo(
        EmdNumber || null,
        numberVoucherHtml,
        name,
        infoEmployee({ VoucherTravellers: itemData.VoucherTravellers.filter(({ ID }) => ID === TravellerId) }),
        date,
        price,
        false,
        infoCompany(companies, actualVersion),
        null,
      );

      return (
        <div key={ index } className={ `${styles.row} ${styles.additional_service}` }>
          <div className={ styles['col-1-16'] }>
            { checkboxItem }
          </div>
          <div className={ styles['col-13-16'] }>
            <div className={ styles.row }>
              <div className={ `${styles['col-1-7']} ${styles['icon-row']}` }>
                <div className='airplane_ticket' />
                <div className='trip-id'>
                  #
                  { Id }
                </div>
              </div>
              { info }
            </div>
          </div>
        </div>
      );
    });
};

const renderOperations = (operations) => {
  if (!operations) {
    return null;
  }

  return (
    <div className={ `${styles.row} ${styles.operations_wrapper}` }>
      <div className={ `${styles['col-1-16']}` } />
      <div className={ `${styles['col-14-16']}` }>
        { operations }
      </div>
      <div className={ `${styles['col-1-16']}` } />
    </div>
  );
};

export function renderItem(opts) {
  const {
    item,
    handleChangeCheckbox,
    checkboxes,
    checkboxDisabled,
    integrationVersions,
    tripChange1C,
    statusBook,
  } = opts;
  const {
    Id,
    ActualVersion: {
      Status,
      ServiceType,
      Id: ActualVersionId,
      ProviderName,
      CurrencyInfo,
    },
    Versions,
    ApprovedStatus,
  } = item;

  const approvedBy = ApprovedStatus.find(({ IsApproved, ApprovedBy }) => IsApproved && ApprovedBy);

  const approvedWithoutBy = ApprovedStatus.find(({ IsApproved, ApprovedBy }) => IsApproved && !ApprovedBy);

  const isTaxi = ServiceType === SERVICETYPE.TAXI;
  const isAir = ServiceType === SERVICETYPE.AIR;
  const isAwaitingConfirmation = statusBook === TRIPSTATUS.AWAITINGCONFIRMATION;
  const showInfoAirTicket = isAir && isAwaitingConfirmation;

  const isUploadedIntegrationVersion = actualIntegrationVersion(integrationVersions, ActualVersionId)
    && actualIntegrationVersion(integrationVersions, ActualVersionId).Uploaded;

  const checkboxItem = (!isUploadedIntegrationVersion || tripChange1C) && !isTaxi
    ? (
      <Checkbox
        theme={ COMPONENTS.CHECKBOX.THEME.MAIN }
        value={ checkboxes.indexOf(Id) !== -1 }
        onChange={ () => handleChangeCheckbox(Id) }
        disabled={ checkboxDisabled }
      />
    )
    : null;

  if (Status === STATUSCODES.HIDDEN) return null;

  const {
    description, history, info, actions, integration, operations,
  } = render(opts);

  const errorHtml = status === STATUSCODES.CANCEL
    ? (
      <div className={ `${styles['col-2-16']} ${styles['col-error']} ${styles['no-valid']}` }>Отменен</div>
    )
    : null;

  const hasIntegration = Versions
    .filter((version) => integrationVersions
      .filter(({ TripItemVersionId }) => version.Id === TripItemVersionId).length > 0).length > 0;
  const integrationHtml = integration && (approvedBy && !approvedBy.IsApproved) && hasIntegration
    ? <div className={ `${styles['col-1-4']} ${styles['history-title']} ${styles.integration_version__title}` }>Интеграция с 1С</div>
    : null;

  const getDateAndMonth = (time) => formatDate(time, DAYMONTHPATTERN);
  const getHoursAndMinutes = (time) => formatDate(dateParseZone(time), DEFAULTTIME);

  const renderApproved = () => {
    if ((approvedBy && !approvedBy.IsApproved) && (approvedWithoutBy && !approvedWithoutBy.IsApproved)) {
      return null;
    }

    const approvedByHtml = !integration && (approvedBy && approvedBy.IsApproved) && (
      <div>
        Подтверждено
        { ' ' }
        { getDateAndMonth(approvedBy.ApprovedTime) }
        { ' ' }
        в
        { ' ' }
        { getHoursAndMinutes(approvedBy.ApprovedTime) }
        { ' ' }
        { approvedBy.ApprovedBy }
      </div>
    );

    const approvedWithoutByHtml = (approvedWithoutBy && approvedWithoutBy.IsApproved) && (
      <div>
        Подтверждено
        { ' ' }
        { getDateAndMonth(approvedWithoutBy.ApprovedTime) }
        { ' ' }
        в
        { ' ' }
        { getHoursAndMinutes(approvedWithoutBy.ApprovedTime) }
      </div>
    );

    return (
      <div className={ `${styles['col-1-4']} ${styles['history-title']} ${styles.approved}` }>
        { approvedByHtml }
        { approvedWithoutByHtml }
      </div>
    );
  };

  const approvedHtml = renderApproved();

  const integrationAndApprovedHtml = (
    integration
    && approvedBy
    && approvedBy.IsApproved
    && ServiceType !== 'Hotel' && (
      <div className={ `${styles['col-1-4']} ${styles['history-title']} ${styles.integration_version__title}` }>
        Интеграция с 1С
        <div className={ styles.approved }>
          Подтверждено
          { ' ' }
          { getDateAndMonth(approvedBy.ApprovedTime) }
          { ' ' }
          в
          { ' ' }
          { getHoursAndMinutes(approvedBy.ApprovedTime) }
          { ' ' }
          { approvedBy.ApprovedBy }
        </div>
      </div>
    )
  );

  const infoTicketCommission = showInfoAirTicket && (
    <div className={ `${styles['col-1-8']} ${styles['history-title']}` }>
      { TITLE.COMISSION }
    </div>
  );

  const infoTicketFee = showInfoAirTicket && (
    <div className={ `${styles['col-1-8']} ${styles['history-title']}` }>
      { TITLE.FEE }
    </div>
  );

  const renderCurrency = () => {
    const condition =
      ProviderName === HOTEL_PROVIDER_VALUE.tripcom
      || ProviderName === HOTEL_PROVIDER_VALUE.expedia;

    if (!condition || !CurrencyInfo) return null;

    return (
      <div className={ `${styles['col-1-8']} ${styles['history-title']} ${styles.rate}` }>
        { TITLE.CURRENCY }
      </div>
    );
  };

  const historyHeader = history
    ? (
      <div className={ `${styles.history} ${styles['history-title-box']}` }>
        <div className={ styles.item }>
          <div className={ styles.row }>
            <div className={ `${styles.row} ${styles['col-1-3']}` }>
              <div className={ `${styles['col-1-4']} ${styles['history-title']}` }>
                TripItem
                <br />
                VersionId
              </div>
              <div className={ `${styles['col-1-4']} ${styles['history-title']}` }>
                Operation
                <br />
                DetailsId
              </div>
              <div className={ `${styles['col-2-4']} ${styles['history-title']}` }>
                Версия
              </div>
            </div>
            <div className={ `${styles.row} ${styles['col-2-3']}` }>
              <div className={ `${styles['col-1-4']} ${styles['history-title']}` }>
                { TITLE.USER }
              </div>
              <div className={ `${styles['col-1-4']} ${styles['history-title']}` }>
                { TITLE.DATE }
              </div>
              <div className={ `${styles['col-1-5']} ${styles['history-title']}` }>
                { TITLE.SUM }
              </div>
              { renderCurrency() }
              { infoTicketCommission }
              { infoTicketFee }
              { integrationAndApprovedHtml }
              { integrationHtml }
              { approvedHtml }
            </div>
          </div>
        </div>
      </div>
    )
    : null;

  const additionalServicesOld = renderAdditionalServices(checkboxItem, opts);
  const operationsHtml = renderOperations(operations);

  return (
    <div key={ Id } className={ styles.row }>
      <div className={ styles.row }>
        <div className={ styles['col-1-16'] }>
          { checkboxItem }
        </div>
        <div className={ styles['col-13-16'] }>
          <div className={ `${styles.row} ${styles.item_type}` }>
            <div className={ `${styles['col-1-7']} ${styles['icon-row']}` }>
              <div className={ styles.version }>TripItemId</div>
              <div className={ styles.version_id }>
                #
                { Id }
              </div>
              { renderIcon(description, ServiceType) }
            </div>
            { info }
          </div>
        </div>
        { actions }
        { errorHtml }
      </div>
      { operationsHtml }
      { additionalServicesOld }
      { historyHeader }
      { history }
    </div>
  );
}

export function renderVersions(versions, onCopyClick, onEditClick, versionProps) {
  const renderVersion = () => versions.map((item, index) => (
    <Version
      { ...versionProps }
      key={ item.TripId || index }
      item={ item }
      onCopyClick={ onCopyClick }
      onEditClick={ onEditClick }
    />
  ));

  const label = versions && versions.length && versions[0].Status === 4
    ? LABELS.ITEMS_IN_CART
    : LABELS.UNSAVE_EDIT;

  if (versions && versions.length) {
    return (
      <div className={ styles['col-5-9'] }>
        <div className={ styles.panel }>
          <div className={ styles.title }>{ label }</div>
          { renderVersion() }
        </div>
      </div>
    );
  }

  return null;
}
