import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, Select, COMPONENTS,
} from 'sw-ui';

import { FlatButton } from '../../../../components/button';
import Input from '../../../../components/input';
import { Rates } from './components/Rates';
import SelectCompany from '../selectCompany';

import { getLabelCheckboxItem } from '../../../../bi/utils/insurance';

import {
  TRIPSLABELS,
  PRICELABELS,
  FIELDS,
  FUNDSFIELDS,
} from '../../../../bi/constants/trips';
import { SERVICETYPERU } from '../../../../bi/constants/serviceType';
import { PROVIDERS_INSURANCE } from '../../../../bi/constants/insurance';

import styles from '../../styles/form.module.scss';

const { SELECT: { THEME: { BORDER } } } = COMPONENTS;

const LABELS = {
  SELECT_ITEMS: 'Выберите услуги, которые нужно застраховать:',
  SELECT_ALL_ITEMS: 'Выбрать все услуги',
  DELETE_ALL_ITEMS: 'Удалить все выбранные услуги',
  ADD_BUTTON: 'Добавить страховку',
};

const Insurance = ({
  item,
  companies,
  tripService,
  guidService,
  addTrip,
  onConfirm,
  tripId,
}) => {
  const {
    insurance: {
      rates,
      items,
      companyId,
      provider,
      selectedItems,
      selectedRate,
      price: { Base, Commission },
      validation,
    },
  } = tripService.get();
  const [isLoading, setIsLoading] = useState(false);

  const isSelectedAllItems = items.length === selectedItems.length;
  const labelButtonSelectitems = isSelectedAllItems
    ? LABELS.DELETE_ALL_ITEMS
    : LABELS.SELECT_ALL_ITEMS;

  useEffect(() => {
    if (!item.isEdit) {
      tripService.getItemsForInsurance(tripId);
    }
  }, []);

  const validateFields = (field, value) => {
    tripService.insuranceTripFieldValidation(field, value);
  };

  const validateBeforeSave = () => {
    tripService.insuranceTripFieldValidation(FIELDS.COMPANYID, companyId);
    tripService.insuranceTripFieldValidation(FIELDS.BASEPRICE, Base);
    tripService.insuranceTripFieldValidation(FUNDSFIELDS.COMMISSION, Commission);
    tripService.insuranceTripFieldValidation(FIELDS.SELECTED_ITEMS, selectedItems);
    tripService.insuranceTripFieldValidation(FIELDS.SELECTED_RATE, selectedRate);
  };

  const handleConfirm = async () => {
    validateBeforeSave();

    const Guid = guidService.generate();
    setIsLoading(true);
    const data = await tripService.prepareDataForSave(Guid);

    if (data) {
      addTrip(data);
      onConfirm(data);
    }
    setIsLoading(false);
  };

  const handleRecalculateRates = async () => {
    await tripService.recalculateRates();
  };

  const handleChangeSelectedItems = () => {
    if (isSelectedAllItems) {
      tripService.deleteAllSelectedItemsInsurance();
    } else {
      tripService.selectAllItemsInsurance();
    }

    return handleRecalculateRates();
  };

  const handleSelectInsuranceItem = (service) => {
    tripService.selectInsuranceItem(service);

    handleRecalculateRates();
  };

  const renderSelectItemsInsurance = () => items.map((service, idx) => {
    const {
      Data, Amount, ServiceType, TripItemId,
    } = service;

    const infoAboutService = getLabelCheckboxItem(Data, ServiceType);
    const isSelectedItem = !!selectedItems.find(({ TripItemId: id }) => id === TripItemId);

    return (
      <Checkbox
        value={ isSelectedItem }
        onChange={ () => handleSelectInsuranceItem(service) }
        key={ idx }
      >
        <p>
          { SERVICETYPERU[ServiceType] }
          :
          { ' ' }
          { infoAboutService }
          ,
          { ' ' }
          { Amount }
          { ' ' }
          ₽
        </p>
      </Checkbox>
    );
  });

  const renderErrorSelectItems = () => {
    if (!validation.SelectedItems) return null;

    return <span className='error-msg'>{ validation.SelectedItems }</span>;
  };

  const renderErrorSelectRate = () => {
    if (!validation.SelectedRate) return null;

    return <span className='error-msg'>{ validation.SelectedRate }</span>;
  };

  return (
    <div className={ styles.wrap }>
      <div className={ styles.row }>
        <SelectCompany
          currentCompanyId={ companyId }
          companies={ companies }
          onSelect={ tripService.selectCompanyId }
          validationCompany={ validation.CompanyId }
        />
      </div>
      <div className={ styles.row }>
        <Select
          label={ TRIPSLABELS.NAME_PROVIDER }
          items={ PROVIDERS_INSURANCE }
          value={ provider }
          theme={ BORDER }
          onChange={ ({ value }) => tripService.setProviderInsurance(value) }
        />
      </div>
      <div className={ styles.row }>
        <div className={ styles.services }>
          <h4>{ LABELS.SELECT_ITEMS }</h4>
          <FlatButton
            label={ labelButtonSelectitems }
            onClick={ handleChangeSelectedItems }
          />
        </div>
        { renderSelectItemsInsurance() }
        { renderErrorSelectItems() }
      </div>
      <Rates
        rates={ rates }
        selectedRate={ selectedRate }
        onSelectRate={ tripService.selectRateInsurance }
      />
      { renderErrorSelectRate() }
      <div className={ styles.row }>
        <div className={ styles['col-1-5'] }>
          <Input
            field={ FIELDS.BASEPRICE }
            value={ Base }
            type='number'
            label={ PRICELABELS.BASEPRICE }
            onChange={ (e, field, value) => tripService.changePriceInsurance(field, value) }
            onBlur={ (e, field) => validateFields(field, Base) }
            valid={ validation.Base }
          />
        </div>
        <div className={ styles['col-1-5'] }>
          <Input
            field={ FUNDSFIELDS.COMMISSION }
            value={ Commission }
            type='number'
            label={ PRICELABELS.COMMISSIONSMARTWAY }
            onChange={ (e, field, value) => tripService.changePriceInsurance(field, value) }
            onBlur={ (e, field) => validateFields(field, Commission) }
            valid={ validation.Commission }
          />
        </div>
      </div>
      <div className={ styles.row }>
        <div className={ styles['col-1-3'] }>
          <FlatButton
            label={ LABELS.ADD_BUTTON }
            onClick={ handleConfirm }
            disabled={ isLoading }
          />
        </div>
      </div>
    </div>
  );
};

Insurance.propTypes = {
  item: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  tripService: PropTypes.object.isRequired,
  guidService: PropTypes.object.isRequired,
  addTrip: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  tripId: PropTypes.number.isRequired,
};

export { Insurance };
