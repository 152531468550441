import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import AjaxButton from '../../../../components/ajaxButton';
import Input from '../../../../components/input';
import Checkbox from '../../../../components/checkbox';

import { HOTEL_PROVIDER_VALUE } from '../../../../bi/constants/hotel';

import styles from '../../styles/trip.module.scss';
import styl from '../../styles/form.module.scss';

const PROVIDERS = [
  { label: 'Островок', value: 'ostrovok' },
  { label: 'Броневик онлайн', value: 'bronevik-online' },
  { label: 'Академсервис', value: 'academ' },
  { label: 'Expedia', value: 'expedia' },
  { label: 'Trip.com', value: 'tripcom' },
  { label: 'Direct', value: 'direct' },
  { label: 'A&A', value: 'aanda' },
  { label: 'hotelbook', value: 'hotelbook' },
  { label: 'TravelLine', value: 'travelline' },
  { label: 'Verdeho3D', value: 'verdeho3d' },
  { label: 'Verdeho', value: 'verdeho' },
  { label: 'Satguru', value: 'satguru' },
  { label: 'Alvist', value: 'alvist' },
  { label: 'Go Global Travel', value: 'goGlobalTravel' },
  { label: 'РЦБ', value: 'rcb' },
  { label: 'verdeho agent', value: 'verdeho agent' },
];

const LABELS = {
  NUMBER_BOOKING: 'Номер бронирования',
  ADD: 'Добавить',
  GROUP_BOOKING: 'Групповое бронирование',
};

const SelectKnownOrder = ({
  onInfoFetched,
  tripService,
}) => {
  const [provider, setProvider] = useState('default');
  const [orderId, setOrderId] = useState('');
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [validationProvider, setValidationProvider] = useState('');
  const [isGroupBooking, setIsGroupBooking] = useState(false);

  const isNotShowGroupBooking =
    provider === HOTEL_PROVIDER_VALUE.expedia
    || provider === HOTEL_PROVIDER_VALUE.tripcom
    || provider === 'default'
    // скрытый релиз групповых бронирований Отеля (удалить строку ниже)
    || true;

  const handleChangeProvider = (event) => setProvider(event.target.value);

  const handleChangeOrderId = (event) => setOrderId(event.target.value);

  const handleChangeIsGroupBooking = (event) => {
    setIsGroupBooking(event.target.checked);
    setOrderId('');
  };

  const validateProvider = () => {
    const valid = tripService.hotelTripFieldValidation('Provider', provider);

    setValidationProvider(valid);

    return !valid;
  };

  const getInfo = async (event) => {
    event.preventDefault();

    if (!validateProvider()) return;

    let newItem = {};
    const providerName = provider === HOTEL_PROVIDER_VALUE['bronevik-online']
      ? HOTEL_PROVIDER_VALUE.bronevik
      : provider;

    setWaitingResponse(true);

    try {
      const data = await tripService.getOrderHotelInfo(providerName, orderId);

      if (!data.VatDetails || !data.VatDetails.length) {
        data.InternalVat = {
          VatReady: false,
          VatInfo: null,
        };

        newItem = tripService.convertHotelMetadata(data, provider);
      } else {
        if (!data.VAT) {
          data.InternalVat = {
            VatReady: true,
            VatInfo: {
              HasVat: false,
              RackRate: data.RackRate ? Number(data.RackRate) : 0,
              VatDetails: [],
            },
          };
        } else {
          const obj = {
            1: {
              Base: Number(data.Price),
              RackRate: data.RackRate ? Number(data.RackRate) : 0,
              Provider: providerName,
              VatDetails: [
                {
                  Rate: Number(data.VatDetails[0].Rate),
                  Amount: Number(data.VatDetails[0].Amount),
                },
              ],
            },
          };

          // TODO перенести в сервис
          const { 1: { ShowVat } } = await tripService.getValidation(obj);

          data.VAT = ShowVat;
          data.InternalVat = {
            VatReady: true,
            VatInfo: {
              HasVat: true,
              RackRate: data.RackRate ? Number(data.RackRate) : 0,
              VatDetails: [
                {
                  Rate: Number(data.VatDetails[0].Rate),
                  Amount: Number(data.VatDetails[0].Amount),
                },
              ],
            },
          };
        }

        newItem = tripService.convertHotelMetadata(data, provider);
      }
    } catch (e) {
      const newItemType = isGroupBooking ? SERVICETYPE.GROUP_HOTEL : SERVICETYPE.HOTEL;

      newItem = tripService.addNewItem(newItemType, provider);
    } finally {
      setWaitingResponse(false);
      onInfoFetched(newItem);
    }
  };

  const renderProviderSelect = () => {
    const validInfo = validationProvider;
    const providersHtml = PROVIDERS.map(({ value, label }) => (
      <option key={ value } value={ value }>{ label }</option>
    ));

    return (
      <div className={ `${styles['col-1-3']} ${styl.select}` }>
        <div className={ `${styl['select-provider']} ${validInfo ? styl.invalid : ''}` }>
          <select
            value={ provider }
            onChange={ handleChangeProvider }
            onBlur={ validateProvider }
          >
            <option disabled value='default'>Выберите провайдера</option>
            { providersHtml }
          </select>
        </div>
        <div className={ styl.invalid }>{ validInfo }</div>
      </div>
    );
  };

  const renderOrderIdInput = () => (
    <div className={ styles['col-1-3'] }>
      <Input
        field=''
        value={ orderId }
        label={ LABELS.NUMBER_BOOKING }
        onChange={ handleChangeOrderId }
        disabled={ isGroupBooking }
      />
    </div>
  );

  const renderGetInfoButton = () => (
    <div className={ `${styles['col-1-3']} ${styl['add-provider']}` }>
      <AjaxButton
        label={ LABELS.ADD }
        loading={ waitingResponse }
        onClick={ getInfo }
      />
    </div>
  );

  const renderGroupBookingCheckbox = () => !isNotShowGroupBooking && (
    <div>
      <Checkbox
        field=''
        label={ LABELS.GROUP_BOOKING }
        value={ isGroupBooking }
        onChange={ handleChangeIsGroupBooking }
      />
    </div>
  );

  return (
    <div className={ styles.row }>
      <div className={ `${styles.panel} ${styles['col-1-2']}` }>
        <form className={ `${styles.row} ${styles.provider}` }>
          <div className={ `${styles.row}` } >
            { renderProviderSelect() }
            { renderOrderIdInput() }
            { renderGetInfoButton() }
          </div>
          { renderGroupBookingCheckbox() }
        </form>
      </div>
    </div>
  );
};

SelectKnownOrder.propTypes = {
  onInfoFetched: PropTypes.func.isRequired,
  tripService: PropTypes.object.isRequired,
};

export default SelectKnownOrder;
